.NoteViewer {
  input[type=date] {
    position: relative;
  }

  input[type=date]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

  .ui[class*="very basic"].table {
    width: 100%;
    max-width: 100%;
  }
}