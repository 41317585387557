@import 'react-datepicker/dist/react-datepicker.css';
@import 'semantic-ui-css/semantic.min.css';

html, body {
  background-color: white;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;

  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  > .ui.modals {
    z-index: 1002;

    .close {
      float: right;
      cursor: pointer;
      margin-top: -1px;
    }
  }
}

body,
select,
input,
.react-datepicker,
textarea,
.ui.input > input,
.ui.button {
  font-family: Arimo, Arial, 'Helvetica Neue', sans-serif;
}

textarea {
  line-height: 20px;
  width: 100%;
  padding: 8px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
}

* {
  box-sizing: border-box;
  outline: none;
}

svg {
  vertical-align: middle;
}

@media screen and (max-width: 480px), screen and (max-height: 480px) {
  .not-mobile {
    display: none !important;
  }
}

.react-datepicker {
  z-index: 1000;
}

.ui.button {
  &.blue {
    background-color: #1e3f7d;
  }
}

.blurring.dimmable > .inverted.dimmer {
  background-color: rgba(255, 255, 255, 0.4);
}