.overlay {
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 1;
  pointer-events: none;

  > * {
    pointer-events: all;
  }

  .title {
    position: absolute;
    left: 60px;
    right: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(255, 255, 255, 0.7);
    mix-blend-mode: multiply;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    padding-top: 2px;
    user-select: none;
    pointer-events: none;

    @media screen and (max-width: 480px) {
      left: 0;
      right: 0;
    }

    .edit {
      margin-left: 10px;
      opacity: 0.5;
      cursor: pointer;
      vertical-align: text-top;
      transition: opacity 0.2s linear;
      pointer-events: all;

      &:hover {
        opacity: 0.7;
      }
    }

    .subtitle {
      font-size: 11px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      display: block;
    }
  }

  .ui.icon.button {
    opacity: 0.5;
    transition: opacity 0.2s linear;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;

    &:hover { opacity: 0.7; }
  }

  > .ui.button {
    position: absolute;

    // Shared
    &.close { top: 0; left: 0; }
    &.toggle-split {
      bottom: 95px; left: 0;
      background-size: 24px 24px;
      background-repeat: no-repeat;
      background-position: center;

      &.panorama {
        background-image: url(../img/camera-360.svg);
      }

      &.bim {
        background-image: url(../img/bim.svg);
      }
    }

    // Panorama
    &.info { top: 50px; left: 0; border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
    &.camera { top: 91px; left: 0; border-top-left-radius: 0; border-top-right-radius: 0; }

    // BIM
    &.toggle-status { top: 50px; left: 0; }
  }

  .zoom {
    position: absolute;
    bottom: 0;
  }

  .date {
    position: absolute;
    bottom: 0;
    left: calc(50% - 114px);

    &.compact {
      left: auto;
      right: 0;
    }

    @media screen and (orientation:portrait) {
      left: auto;
      right: 0;
    }
  }

  .status-key {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    padding: 10px;
    color: white;
    line-height: 20px;
  }
}
