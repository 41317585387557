.PannellumViewer {
  display: block;
  height: 100%;
  background-color: #f2f2f2;
  overflow: hidden;
  position: relative;

  @import './shared/overlay.scss';

  .ui.inverted.dimmer.scene {
    background-color: transparent;
  }

  .panorama {
    z-index: 0;

    &.pnlm-container {
      background: #eee;

      .pnlm-load-box {
        display: none !important;
      }

      .hotspot {

        // Hotspots on the floor
        &.scene {
          width: 35px;
          height: 35px;
          opacity: 0.5;
          transition: opacity 0.2s linear;
          background: url(./img/hotspot-scene.png) center no-repeat;
          background-size: 99% 99%;
          mix-blend-mode: overlay;

          &:hover {
            opacity: 1.0;
          }
        }

        // Notes
        &.info {
          color: black;
          width: 40px;
          height: 40px;
          top: -20px; // Pannellum centres it, so we offset up by 20px
          border-radius: 4px;
          pointer-events: all;
          text-align: center;
          transition: none;
          cursor: pointer;
          pointer-events: all;
          filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.333));

          background-color: white;
          background-image: url(./img/hotspot-info.svg);
          background-size: 70%;
          background-repeat: no-repeat;
          background-position: center 45%;

          // Arrow on the bottom
          &::after {
            content: " ";
            position: absolute;
            left: 12px;
            bottom: -8px;
            border-top: 8px solid white;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            border-bottom: none;
          }
        }

      }
    }
  }
}

.panorama-info-content {
  padding: 0 !important;

  .ui.very.basic.padded.table {
    td {
      white-space: nowrap;
      &:first-child {
        padding-left: 21px;
        font-weight: 600;
      }
      &:last-child {
        padding-right: 21px;
      }
    }
  }

}

.panorama-upload-captures-content {
  p {
    border: 2px dashed rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: 256px;
    line-height: 256px;
    text-align: center;
    user-select: none;
  }
}