.App {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  min-width: 320px;
  min-height: 320px;
}