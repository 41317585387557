.HeaderView {
  display: block;
  justify-content: flex-start;
  align-items: center;
  background-color: #1e3f7d;
  color: white;
  height: 60px;
  min-height: 60px;
  line-height: 60px;
  font-size: 16px;
  padding: 0 20px;
  white-space: nowrap;
  text-overflow: clip;

  .vertical-divider {
    display: inline-block;
    height: 28px;
    background-color: white;
    margin: 0 20px;
    width: 2px;
    vertical-align: middle;
  }

  .actions {
    float: right;
    white-space: nowrap;

    svg {
      margin-bottom: 4px;
    }

    > * {
      margin-left: 20px;
    }
  }

  .ui.top.right.pointing.dropdown > .menu {
    margin: -5px -5px 0 0;
  }

  @media screen and (max-height: 480px) {
    height: 50px;
    min-height: 50px;
    line-height: 50px;
  }

  @media screen and (max-width: 480px) {
    .breadcrumbs {
      display: none;
    }
  }
}