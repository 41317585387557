.SignInView {
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    width: 375px;
    max-width: calc(100% - 40px);

    .logo {
      height: 64px;
      line-height: 64px;
      background: url(./img/signin-logo.png) no-repeat center;
      background-size: contain;
      margin: 0 0 40px;
    }

    p, .ui.input {
      margin-bottom: 30px;
    }

    .ui.input, .ui.button {
      height: 40px;
    }
  }
}