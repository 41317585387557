.ContilioLogo {
  display: inline-block;
  font-family: Montserrat, Arimo, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  white-space: nowrap;

  svg {
    vertical-align: middle;
    margin-bottom: 6px;
    margin-right: 6px;
  }
}