.DateInput {
  color: rgba(0, 0, 0, 0.6);

  > * {
    vertical-align: bottom;
  }

  .ui.button {
    height: 40px;

    &.prev {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      float: left;
    }

    &.next {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      float: right;
    }
  }

  .react-datepicker-wrapper {
    position: relative;
    width: 142px;
    white-space: nowrap;

    .ui.button {
      font-size: 16px;
      font-weight: 500;
      width: 140px;
      margin: 0 1px;
      border-radius: 0;
      text-align: center;
      padding: 1px 0 0;
      opacity: 0.5;
      transition: opacity 0.2s linear;

      &:hover { opacity: 0.7; }

      svg {
        vertical-align: middle;
        margin-right: 7px;
        margin-bottom: 2px;
      }
    }
  }

  .react-datepicker__month {
    min-height: 174px;
  }

  &.compact {
    .ui.icon.button {
      display: none;
    }

    .react-datepicker-wrapper {
      .ui.button {
        border-radius: 4px;
      }
    }
  }
}