.Floorplan {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  top: 0;
  right: 0;
  width: 160px;
  max-width: calc(100% - 50px);
  background-color: white;
  border-radius: 4px;
  padding: 9px 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
  transition: width 0.2s ease-out;

  &.maximized {
    width: 480px;
    max-width: 100%;
  }

  > div {
    position: relative;
    width: 100%;
  }

  .actions {
    float: right;

    span {
      cursor: pointer;
    }
  }

  .img-display {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      background-size: contain;
      user-select: none;
    }
  }

  .marker-current {
    position: absolute;
    width: 42px;
    height: 42px;
    background: url('./floorplan-marker-current.svg') center no-repeat;
    z-index: 2;
    pointer-events: none;
  }

  .marker-note,
  .marker-other {
    position: absolute;
    width: 24px;
    height: 24px;
    background: url('./floorplan-marker-other.svg') center no-repeat;
    opacity: 0.1;
    z-index: 0;
    cursor: pointer;
    transition: opacity 0.2s linear;
    z-index: 1;

    &:hover {
      opacity: 0.3;
    }
  }

  .marker-note {
    background-image: url('./floorplan-marker-note.svg');
    opacity: 0.4;
    pointer-events: none;
  }
}