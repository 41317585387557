.FooterView {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  font-size: 12px;
  color: grey;
  height: 50px;
  min-height: 50px;
  padding: 0 20px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-height: 480px) {
    display: none;
  }
}