.BimViewer {
  display: block;
  height: 100%;
  background-color: #eee;
  overflow: hidden;

  @import './shared/overlay.scss';

  canvas {
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

hr.popup-divider {
  border: none;
  border-top: 1px dotted rgba(255, 255, 255, 0.2);
  padding-bottom: 14px;
  margin: -2px -14px 0;
}
