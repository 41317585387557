.ViewersView {
  width: 100%;
  height: 100%;

  &.reflex-container {
    overflow: hidden;
    height: 100%;
    padding: 4px;

    .reflex-element {
      border-radius: 8px;
      overflow: hidden;
    }

    > .reflex-splitter,
    > .reflex-splitter.reflex-thin {
      z-index: 1001;

      .handle {
        position: absolute;
        top: calc(50% - 20px);
        left: -20px;
        width: 40px;
        height: 40px;
        background-color: #1e3f7d;
        border: 2px solid white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.vertical > .reflex-splitter,
    &.vertical > .reflex-splitter.reflex-thin {
      background-color: #ccc;
      border-left: 4px solid white;
      border-right: 4px solid white;
      width: 9px !important;
      margin: 0 !important;
      position: relative;
      opacity: 1;

      &:hover,
      &.active {
        border-left: 4px solid white;
        border-right: 4px solid white;
      }
    }

    &.horizontal > .reflex-splitter,
    &.horizontal > .reflex-splitter.reflex-thin {
      background-color: #ccc;
      border-top: 4px solid white;
      border-bottom: 4px solid white;
      height: 9px !important;
      margin: 0 !important;
      position: relative;
      opacity: 1;

      &:hover,
      &.active {
        border-top: 4px solid white;
        border-bottom: 4px solid white;
      }

      > .handle {
        top: -20px;
        left: calc(50% - 20px);
      }
    }

  }
}