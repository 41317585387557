.ZoomInput {
  width: 40px;
  padding: 0;
  margin: 20px 0;

  .thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background-color: rgba(224, 225, 226, 0.4);
    color: rgba(0, 0, 0, 0.6);
    transition: background-color 0.2s linear;
    outline: none;

    &:hover {
      background-color: rgba(224, 225, 226, 0.6);
    }
  }

  .track {
    position: relative;
    height: 45px;
    width: 100%;

    .fill {
      position: absolute;
      top: -20px;
      width: 100%;
      height: calc(100% + 40px);

      background-color: rgba(224, 225, 226, 0.2);
      border-radius: 20px;
    }
  }
}